<template>
  <div class="page">
    <div class="top_box">
      居家养老四件套包括智能门磁、无线紧急按钮、无线报警主机和定时抄表拍照终端。对老人住所和人身安全进行24小时全方位检测，一旦发生意外情况将在第一时间报送至后台指挥中心，工作人员会马上联系老人及老人家属。
    </div>
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="title">{{ item.name }}</div>
      <div class="img">
        <img :src="item.picUrl" />
      </div>
    </div>
    <div class="btn" @click="toPath()" v-if="sumObj.residueQuantity > 0">
      立即申请
    </div>
    <div
      v-else
      class="btn"
      style="
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: rgba(0, 0, 0, 0.25);
      "
    >
      名额已满
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getResidueSumsUrl } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
export default {
  name: "safetyShield",
  data() {
    return {
      list: [
        {
          name: "智能门磁",
          picUrl: require("./img/smartDoorMagnet.png"),
        },
        {
          name: "无线紧急按钮",
          picUrl: require("./img/smokeDetector.png"),
        },
        {
          name: "无线报警主机",
          picUrl: require("./img/gasAlarm.png"),
        },
        {
          name: "定时抄表拍照终端",
          picUrl: require("./img/sleepBreathingMonitor.png"),
        },
      ],
      sumObj: {},
    };
  },
  created() {
    this.getSumObj();
  },
  mounted() {
    gloabalCount("", 72, 1);
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  methods: {
    async getSumObj() {
      let params = {
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getResidueSumsUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        this.sumObj = res.data;
      }
    },
    async toPath() {
      if (await toRegister(this.$route.path, this.$route.query, "安全智护")) {
        this.$router.push({
          name: "safetyShieldApply",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding: 30px;
  padding-bottom: 40px;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 58px;
  }
  .item {
    .title {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      padding: 50px 0 24px 0;
    }
    .img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .top_box {
    padding: 20px 30px;
    box-sizing: border-box;
    background: #fafafa;
    font-size: 24px;
    color: #1e4112;
  }
}
</style>
